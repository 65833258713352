:root,
:root.dark {
  --app-bg: #050c23;
  --app-color: #fff;
  --app-sub-color: #878faa;
  --modal-bg: rgba(42, 46, 60, 0.8);
  --modal-body-bg: #081336;
  --card-bg: #081336;
  --border-primary-color: #0b1946;
  --border-secondary-color: #545c78;
  --border-tertiary-color: #0f1f59;
  --border-gradient-color: radial-gradient(
    41.46% 82.46% at 53.05% 79.76%,
    #fa7a42 0%,
    #071949 86.35%
  );
  --link-hover-color: #fa7a42;
  --banner-bg: #0f1f59;
  --banner-msg-color: #b6b9c6;
  --tip-bg-color: #11256c;
  --tip-msg-color: #a6b7f2;
  --tip-msg-a-color: #e5e5e5;
  --info-title-color: #a6b7f2;
  --info-label-color-primary: #a6b7f2;
  --info-label-color-secondary: #9fa6bc;
  --info-value-color: #a6b7f2;
  --info-value-unit-color: #545f7d;
  --table-bg: #081336;
  --table-header-color: #a6b7f2;
  --table-title-color: #edf2fc;
  --table-vice-value-color: #6e799a;
  --input-box-bg: #050c23;
  --input-border-color: #0f1f59;
  --input-balance-color: #9fa6bc;
  --input-text-color: #fff;
  --input-placeholder-color: #545f7d;
  --toggle-bg-default: #4b5563;
  --toggle-bg-checked: #4e70e5;
  --toggle-circle-default: #9ca3af;
  --toggle-circle-checked: #fff;
  --summary-item-bg: #0b1946;
  --bar-bg: #222530;
  --bar-override-bg: #31d171;
  --swith-tab-inative-color: #878faa;
  --evt-live-bg: #009e65;
  --evt-live-color: #fff;
  --evt-upcoming-bg: #fff;
  --evt-upcoming-color: #4c4c4c;
  --evt-ended-bg: #545f7d;
  --evt-ended-color: #fff;

  /* **button colors** */
  /* primary */
  --btn-primary-color-default: #fff;
  --btn-primary-color-hover: #fff;
  --btn-primary-color-disabled: #545c78;
  --btn-primary-bg-default: #1e3db4;
  --btn-primary-bg-hover: #19308f;
  --btn-primary-bg-disabled: #aab1c4;
  /* secondary */
  --btn-secondary-color-default: #edf2fc;
  --btn-secondary-color-hover: #fff;
  --btn-secondary-color-disabled: #545f7d;
  --btn-secondary-bg-default: #0b1946;
  --btn-secondary-bg-hover: #0b1946;
  --btn-secondary-bg-disabled: #0f1f59;
  --btn-secondary-border-default: #11256c;
  --btn-secondary-border-hover: #1c37a2;
  /* tertiary */
  --btn-tertiary-color-default: #19308f;
  --btn-tertiary-color-hover: #2245c5;
  --btn-tertiary-color-disabled: #545f7d;
  --btn-tertiary-bg-default: #fff;
  --btn-tertiary-bg-hover: #cad4f7;
  --btn-tertiary-bg-disabled: #0f1f59;
}
