/* -- Archivo -- */
/* font-weight: 300 */
@font-face {
  font-family: 'Archivo Light';
  src: url('../../public/fonts/Archivo/Archivo-Light.ttf');
  font-display: fallback;
}
/* font-weight: 400 */
@font-face {
  font-family: 'Archivo Regular';
  src: url('../../public/fonts/Archivo/Archivo-Regular.ttf');
  font-display: fallback;
}
/* font-weight: 500 */
@font-face {
  font-family: 'Archivo Medium';
  src: url('../../public/fonts/Archivo/Archivo-Medium.ttf');
  font-display: fallback;
}
/* font-weight: 600 */
@font-face {
  font-family: 'Archivo SemiBold';
  src: url('../../public/fonts/Archivo/Archivo-SemiBold.ttf');
  font-display: fallback;
}
/* font-weight: 700 */
@font-face {
  font-family: 'Archivo Bold';
  src: url('../../public/fonts/Archivo/Archivo-Bold.ttf');
  font-display: fallback;
}

/* -- Archivo_SemiExpanded -- */
/* font-weight: 500 */
@font-face {
  font-family: 'Archivo SemiExpanded Medium';
  src: url('../../public/fonts/Archivo_SemiExpanded/Archivo_SemiExpanded-Medium.ttf');
  font-display: fallback;
}
