@import './index';
@import './theme';

html,
body {
  width: 100%;
  min-height: 100%;
}

body {
  position: relative;
  padding: 0;
  margin: 0;
  font-size: 16px;
  font-family: Archivo Regular, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: var(--app-color);
  background: var(--app-bg);
  background-repeat: no-repeat;
  scroll-behavior: smooth;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace;
}

a {
  display: flex;
  color: inherit;
  text-decoration: none;
}

input {
  outline: none;
  padding: 0 rem(1);
  color: var(--input-text-color);

  &::placeholder {
    color: var(--input-placeholder-color);
  }

  &::-webkit-input-placeholder {
    color: var(--input-placeholder-color);
  }

  &:-ms-input-placeholder {
    color: var(--input-placeholder-color);
  }
}

* {
  box-sizing: border-box;
}

.PageContainer {
  padding: rem(48) rem(64);
}

.PageSection {
  padding: rem(64) 10vw;
}
